// @import "variables.scss";

.Header {
    position: fixed;
    top: 0;
    right: 0;
    left: 250px;
    z-index: 1002;
    background-color: $white;
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);

    .navbar {
        display: flex;
        justify-content: space-between;
        height: 70px;
        margin: 0 auto;
        padding: 0 10px 0 0;
        .profile-nav {
            color: $primaryColor;
            padding-right: 25px;
            &::after {
                position: absolute;
                top: 25px;
                right: 0;
            }
            &:focus, &:hover, &:active {
                box-shadow: none;
                text-decoration: none;
            }
        }
    }
}

.Leftbar {
    width: 250px;
    z-index: 1005;
    background-color: $white;
    bottom: 0;
    position: fixed;
    top: 0;
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    .navbar-brand-box {
        height: 70px;
        width: 250px;
        position: fixed;
        z-index: 1;
        padding: 0 1.5rem;
        background-color: $white;
    }
    .sidebar-menu-scroll {
        position: relative;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        height: calc(100% - 70px);
        margin-top: 70px;

        .nav-list {
            list-style: none;
            padding-left: 0;
            text-align: left;
            .nav-item {
                display: block;
                width: 100%;
                &.active {
                    a {
                        background-color: $accentColor;
                        color: $primaryColor;
                    }
                }
                a {
                    display: block;
                    padding: 0.6rem 1.1rem;
                    color: $gray;
                    font-weight: bold;
                    transition: all 0.4s;
                    border-radius: 3px;
                    margin: 0 10px;
                    font-size: 15px;
                    position: relative;
                    &:hover {
                        text-decoration: none;
                    }
                }
                .subnav-list {
                    list-style: none;
                    padding-left: 20px;
                    display: none;
                    &.open {
                        display: block;
                    }
                    .subnav-item {
                        a {
                            font-weight: normal;
                            font-size: 14.5px;
                        }
                    }
                }
            }
        }
    }
}

.App-main {
    margin-left: 250px;
    overflow: hidden;
    padding: 90px 10px 60px;
    text-align: left;
    
    .PageHeader {
        h4 {
            font-weight: 600;
        }
    }

    .card {
        .card-header {
            background-color: $white;
            h6 {
                margin: 0;
                padding: 0;
            }
        }
        .card-footer {
            background-color: $white;
            text-align: center;
            .btn-pagination {
                background-color: $white;
                border: solid 1px $commonGray;
                &:first-child {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                &:last-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
                svg {
                    width: 15px;
                    fill: $gray;
                }
            }
        }
        .card-body {
            .tb-ul {
                padding: 0;
                margin: 0;
                .tb-row {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: solid 1px $commonGray;
                    padding: 15px;
                    &:hover {
                        background-color: $lightGray;
                    }
                    &:last-child {
                        border-bottom: 0;
                    }
                    .row-avatar {
                        width: 60px;
                        height: 60px;
                        .avatar {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

}