$primaryColor: #24205b;
$secondaryColor: #24205b;
$accentColor: #d8b469;

// 
$white: #FFF;
$black: #000;
$red: #FF0000;
$green: #00FF00;
$blue: #0000FF;
//
$gray: #7b8190;

//
$lightGray: #f5f6f8;
$commonGray: #d8d8d8;