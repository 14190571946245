// import starting from the src/ folder
@import "variables.scss";

// can also be relative import
// @import "./variables.scss";

// import admin panel styles
@import "auth.scss";
@import "layouts.scss";

.bg-white {
    background-color: $white;
}

.bg-gray {
    background-color: $lightGray;
}

.shadow {
    box-shadow: 0 2px 4px rgba(15,34,58,.12);
}

ul {
    list-style: none;
    padding: 0;
}

.overlay-loader {
    z-index: 1010;
}

.separator {
    height: 1px;
    width: 100%;
    background: $commonGray;
}

.form-textarea {
    color: #495057;
    padding: 10px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
}

.bubble {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    &.online {
        background-color: green;
    }
    &.offline {
        background-color: red;
    }
}

.number-money-limit-section {
    border: solid 1px #00000020;
    .section-row {
        border-top: solid 1px #00000020;
        margin: 0;
        .section-left {
            border-right: solid 1px #00000020;
        }
        .section-right {
            max-height: 310px;
            overflow-y: auto;
        }
    }
}

.tb-cell {
    height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid rgb(221, 221, 221);
}

.winning-status-table {
    display: flex;
    .tb-column {
        // max-width: 150px;
        min-width: 100px;
        overflow: hidden;
        .tb-row {
            height: 30px;
            padding: 5px;
            border: 1px solid rgb(221, 221, 221);
        }
    }
}

td {
    text-align: center;
    &.paid {
        background-color: #ced4da;
    }
}
th {
    position: relative;
    text-align: center;
    vertical-align: middle !important;
    &.th-orderby {
        padding-right: 25px !important;
    }
    .TableOrderBy {
        width: 10px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: grid;
    }
}

// Credentials Page
.Credentials {
    .select-https {
        max-width: 100px;
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #f3f3f3;
        &:focus {
            box-shadow: none;
        }
    }
    .input-domain {
        max-width: 400px;
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &:focus {
            box-shadow: none;
        }
    }
    .btn-add-domain {
        min-width: 120px;
    }
}

.tables {
    .border-row {
        border-bottom: solid 1px #ced4da;
        padding: 10px 0;
        margin: 0;
        &:first-child {
            border-top: solid 1px #ced4da;
        }
    }
}

// FromEmail
@keyframes fontZooming {
    0%   {font-size: 16px;}
    50%  {font-size: 18px;}
    100% {font-size: 16px;}
}
// @page {
//     size: 1024px 1900px !important;
// }
@media print {
    .club-card {
        background-color: #FFFFFF;
        border: solid 1px #ced4da;
        padding: 30px;
        .grid-holes {
            position: relative !important;
            display: grid !important;
            grid-template-columns: auto auto auto !important;
            padding: 10px !important;
            max-width: 900px !important;
            margin-left: auto !important;
            margin-right: auto !important;
            .grid-hole {
                position: relative !important;
                width: 250px !important;
                height: 250px !important;
                padding: 5px !important;
                margin: 20px !important;
                border: solid 1px #ced4da !important;
                border-radius: 6px !important;
                background-color: #FFFFFF !important;
                .map-img {
                    width: 100%;
                }
                .pin-ele {
                    position: absolute !important;
                    width: 16px !important;
                    height: 16px !important;
                    transform: translate(-8px, -8px) !important;
                    display: flex !important;
                    justify-content: center !important;
                    align-items: center !important;
                    font-size: 12px !important;
                    border: solid 1px #000000 !important;
                    border-radius: 50% !important;
                    background-color: yellow !important;
                    cursor: pointer !important;
                }
            }
        }
    }
}
.FromEmail {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: auto;
    .btn-print {
        position: fixed;
        top: 30px;
        right: 60px;
        width: 100px;
        height: 40px;
        font-size: 16px;
        color: white;
        background-color: green;
        border-radius: 30px;
        z-index: 10;
        
        animation-name: fontZooming;
        animation-duration: 3s;
        animation-delay: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;

        box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.3);
        &:hover {
            box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.5);
            -webkit-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.5);
            -moz-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.5);
        }
    }
    .club-card {
        background-color: #FFFFFF;
        border: solid 1px #ced4da;
        padding: 30px;
        .grid-holes {
            position: relative;
            display: grid;
            grid-template-columns: auto auto auto;
            padding: 10px;
            max-width: 900px;
            margin-left: auto;
            margin-right: auto;
            .grid-hole {
                position: relative;
                width: 250px;
                height: 250px;
                padding: 5px;
                margin: 20px;
                border: solid 1px #ced4da;
                border-radius: 6px;
                background-color: #FFFFFF;
                .map-img {
                    width: 100%;
                }
                .pin-ele {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    transform: translate(-8px, -8px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    border: solid 1px #000000;
                    border-radius: 50%;
                    background-color: yellow;
                    cursor: pointer;
                }
            }
        }
    }
}